<template>
  <div
    tabindex="0"
    class="modal double-check-modal"
    @keydown.esc.prevent.stop="emit('cancel')"
    @mousedown.self="emit('cancel')"
  >
    <div
      class="modal-container border border-solid border-important-border rounded-xl p-6"
      :class="widthClass"
      @click="focusHiddenInput"
      @mousedown.stop
    >
      <input
        :ref="'hiddenInput'"
        v-autofocus
        readonly="readonly"
        class="hidden-input"
        data-hidden-input="true"
      />
      <PopupHeader
        :title="title"
        :class="'p-0 pb-6'"
        :custom-icon="headerIcon"
        @close="emit('cancel')"
      />

      <slot name="content">
        <div class="flex flex-col mb-6 gap-y-2">
          <div
            v-for="(d, i) in descriptionArr"
            :key="i"
            class="text-sm text-text-default select-text"
          >
            {{ d }}
          </div>
        </div>
      </slot>

      <slot name="footer">
        <div
          v-if="showConfirmButtons"
          class="flex"
          :class="buttonWrapClass"
        >
          <ButtonCustom
            class="cancel-button rounded text-sm text-text-default h-8"
            :class="buttonClasses"
            :text="cancelButtonText || translate('cancel')"
            :type="cancelButtonType"
            @click.native.stop="emit('cancel')"
          />
          <ButtonCustom
            class="confirm-button rounded text-sm h-8"
            :class="`${buttonClasses} ${confirmButtonClass}`"
            :type="confirmButtonType"
            :text="confirmButtonText || translate('confirm')"
            :variation="confirmButtonVariation"
            :disabled="confirmButtonDisabled"
            @click.native.stop="emit('confirm')"
          />
        </div>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onKeyStroke } from '@vueuse/core';
import { getCurrentInstance, onMounted, onUnmounted, computed } from 'vue';
import useTranslate from '@/utilities/composables/useTranslate';
import ButtonCustom from '@/components/elements/ButtonCustom.vue';
import PopupHeader from '@/components/elements/PopupHeader.vue';

const props = withDefaults(
  defineProps<{
    title: string;
    headerIcon?: string;
    description?: string | string[];
    widthClass?: string;
    // buttons
    showConfirmButtons?: boolean;
    buttonWrapClass?: string;
    buttonClasses?: string;
    // confirm button
    confirmButtonText?: string;
    confirmButtonType?: string;
    confirmButtonClass?: string;
    confirmButtonDisabled?: boolean;
    confirmButtonVariation?: string;
    // cancel button
    cancelButtonText?: string;
    cancelButtonType?: string;
  }>(),
  {
    showConfirmButtons: true,
    widthClass: 'w-96',
    buttonClasses: 'flex-1',
    confirmButtonType: 'attention',
    cancelButtonType: 'secondary',
  }
);

const descriptionArr = computed((): string[] => {
  if (!props.description) return [];
  return Array.isArray(props.description) ? props.description : [props.description];
});

const _this = getCurrentInstance()!.proxy as any;
const { translate } = useTranslate();
const emit = defineEmits(['cancel', 'confirm']);

// handle escape key to close confirmation modal
onKeyStroke((e: KeyboardEvent) => {
  if (e.key === 'Escape') {
    e.preventDefault();
    e.stopPropagation();
    emit('cancel');
  }

  if (e.key === 'Enter') {
    e.preventDefault();
    e.stopPropagation();
    emit('confirm');
  }
});

onMounted(() => {
  focusHiddenInput();
  _this.$store.commit('setConfirmationModal', true);
});

onUnmounted(() => {
  _this.$store.commit('setConfirmationModal', false);
});

const focusHiddenInput = () => {
  if (_this.$refs.hiddenInput) {
    _this.$nextTick(() => _this.$refs.hiddenInput?.focus());
  }
};
</script>

<style scoped lang="scss">
.modal-container {
  background-color: var(--backgroundStrongest);
}

.cancel-button {
  box-shadow: 0px 1px 2px 0px #1018280d;
  background-color: var(--importantEl);
  @apply border border-solid border-important-border mr-2;
}
.cancel-button:hover {
  background-color: var(--importantElHover);
}
</style>
